<template :key="arttir">
  <div class="backgroundStyle">
    <!-- NavBar-->
    <navbar-home />
    <!-- Home Swiper İmg -->

    <swiper-home
      v-if="groupedData && allDatas"
      :swiperData="groupedData[6]"
    ></swiper-home>
    <br /><br />
    <br /><br />
    <!-- Cards -->
    <div>
      <b-row>
        <b-col md="12"
          ><second-card
            v-if="groupedData && allDatas"
            :blogList="groupedData[7]"
          ></second-card
        ></b-col>
      </b-row>
    </div>
    <br /><br />
    <br /><br />
    <third-card
      v-if="groupedData && allDatas"
      :ReklamData="groupedData[8]"
    ></third-card>
    <br /><br /><br />
    <fourth-card
      v-if="groupedData && allDatas"
      :aboutCardData="groupedData[9]"
    ></fourth-card>
    <br /><br />
    <fifth-card-vue
      v-if="groupedData && allDatas"
      :miniSideBarData="groupedData[10]"
    ></fifth-card-vue>
    <br /><br />
    <!-- Footer -->
    <footer-vue :AdressData="AdressData"> </footer-vue>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Navbar from "@/layouts/components/Navbar.vue";
import secondCard from "./home/components/secondCard.vue";
import NavbarHome from "./home/components/Navbar.vue";
import footerVue from "./home/components/footer.vue";
import SwiperHome from "./home/components/Swiper.vue";
import thirdCard from "./home/components/thirdCard.vue";
import fourthCard from "./home/components/fourthCard.vue";
import fifthCardVue from "./home/components/fifthCard.vue";
import axios from "axios";
export default {
  components: {
    Swiper,
    SwiperSlide,
    BRow,
    BCol,
    Navbar,
    secondCard,
    NavbarHome,
    footerVue,
    SwiperHome,
    thirdCard,
    fourthCard,
    fifthCardVue,
  },

  data() {
    return {
      allDatas: undefined,
      groupedData: undefined,
      AdressData: [],
      arttir: 0,
    };
  },
  watch:{

  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      // console.log("GET DATA");
      this.groupedData = [];
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(
          `/api/Contents/GetListByPageIdAndLanguage?id=5&language=${languageCode}`
        )
        .then((result) => {
          this.getCommunications();
          if (result.data.success) {
            // console.log("BURADA LOGA BAK");
            // console.log(result.data.data);
            this.allDatas = result.data.data;
            this.splitData(result.data.data);
            //   console.log(this.allDatas[0]);
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
    splitData(myData) {
      myData.forEach((item) => {
        //  console.log("fdgdfgd : ",item)
        const componentId = item.componentId;
        if (!this.groupedData[componentId]) {
          this.groupedData[componentId] = [];
        }
        this.groupedData[componentId].push(item);
      });
    //  console.log(this.groupedData);
    },
    getCommunications() {
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(`/api/Informations/GetListByLanguage?language=${languageCode}`)
        .then((result) => {
          if (result.data.success) {
            this.AdressData = result.data.data;
            //   console.log(this.AdressData)
          } else {
            console.error("API yanıtında başarısızlık:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
  },
};
</script>
<style>
.backgroundStyle {
  background-color: #8ea2b486 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
